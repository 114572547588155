import logo from './logo.svg'
import './App.css'

function App() {
  return (
    <div className='App'>
      <header className='App-header'>
        <img src={logo} className='App-logo' alt='logo' />
        <p>Todo lo que vivimos juntos, podes revivirlo!</p>
        <a
          className='App-link'
          href='https://open.spotify.com/show/0MfLckSl9hHb3Bc21FXNuK'
          target='_blank'
          rel='noopener noreferrer'
        >
          Escucha Estamos Bien Radio
        </a>
      </header>
    </div>
  )
}

export default App
